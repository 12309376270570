<template>
  <Page
    :title="isNew ? 'neue Rechnung' : 'Rechnung ' + item.number"
    color="info"
  >
    <template slot="help"> </template>
    <template slot="extension">
      <v-tabs background-color="info darken-1" hide-slider>
        <v-toolbar color="info darken-1" dense>
          <v-btn text exact :to="abortTarget"
            ><v-icon left>mdi-arrow-left</v-icon>Abbrechen</v-btn
          >
        </v-toolbar>
      </v-tabs>
    </template>
    <v-form v-model="saveAllowed" @input="saveStorage">
      <v-row v-if="item">
        <v-col cols="12" md="6" lg="5" xl="4" offset-xl="2">
          <v-card>
            <v-system-bar>Angaben</v-system-bar>

            <v-list>
              <v-list-item>
                <v-combobox
                  v-model="item.event"
                  label="Anlass (wählen oder schreiben)"
                  placeholder="z.B. «Französisch G27d» oder «Klassentage M27b»"
                  :items="events"
                  :rules="[rules.required]"
                ></v-combobox>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  ref="description"
                  v-model="item.description"
                  label="Bezeichnung"
                  placeholder="z.B. «Lektüre: Le Petit Prince»"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Erstellt am</v-list-item-subtitle>
                  <v-list-item-title
                    ><DateValue :value="item.date" long />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card class="mt-4">
            <v-system-bar>Konto</v-system-bar>
            <v-list>
              <v-list-item>
                <LookupValueInput
                  :items="accounts"
                  v-model="item.account"
                  label="Konto"
                  :rules="[rules.required]"
                />
              </v-list-item>

              <v-list-item
                v-if="item.account && item.account.code === 'teachingMaterial'"
              >
                <GradesInput v-model="item.grades" />
              </v-list-item>

              <v-list-item
                v-if="item.account && item.account.code === 'fieldTrip'"
              >
                <SchoolYearInput
                  v-model="item.schoolYear"
                  :range="[-1, 1]"
                  label="Schuljahr"
                ></SchoolYearInput>
              </v-list-item>
              <v-list v-if="!gym1OK">
                <v-list-item>
                  <v-alert type="error">
                    Schüler:innen GYM2-4 können keine Rechnungen auf Stufe GYM1
                    haben!
                  </v-alert>
                </v-list-item>
              </v-list>
            </v-list>
          </v-card>
          <v-card class="mt-4">
            <v-system-bar>Zahlung</v-system-bar>
            <v-list>
              <v-list-item>
                <LookupValueInput
                  :items="payees"
                  v-model="item.payee"
                  label="Zahlung"
                  :rules="[rules.required]"
                />
              </v-list-item>
              <v-list-item v-if="copies">
                <v-row no-gutters>
                  <v-col>
                    <v-list-item>
                      <v-text-field
                        label="Kopien s/w pro Schüler*in"
                        @focus="$event.target.select()"
                        type="number"
                        min="0"
                        v-model="copiesGrayscale"
                      ></v-text-field>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-text-field
                        label="Kopien farbig pro Schüler*in"
                        @focus="$event.target.select()"
                        type="number"
                        min="0"
                        v-model="copiesColor"
                      ></v-text-field>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-if="copies">
                <v-list-item-content> Kosten pro Person</v-list-item-content>
                <v-list-item-action
                  ><CurrencyValue v-model="copiesAmount" />
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="item.payee && item.payee.code == 'employee'">
                <v-text-field
                  ref="iban"
                  v-model="item.iban"
                  label="IBAN"
                  :rules="[rules.iban]"
                ></v-text-field>
              </v-list-item>
            </v-list>
            <v-divider v-if="copies" />
            <v-card-actions v-if="copies"
              ><v-spacer /><v-btn text @click="assign">Zuweisen</v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mt-4">
            <v-system-bar>Betrag</v-system-bar>
            <InvoiceAmount v-model="item" @updateTolerance="updateTolerance" />
            <v-divider />
            <v-list v-if="!mixedGradesOK">
              <v-list-item>
                <v-alert type="error">
                  Schüler:innen GYM1 und GYM2-4 können nicht gemischt werden!
                </v-alert>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn text @click="distribute">gleichmässig verteilen</v-btn>
              <v-spacer />
              <v-btn
                text
                color="info"
                @click="save"
                :disabled="!savable"
                :loading="saving"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="7" xl="4">
          <v-card v-if="item">
            <v-system-bar>Personen</v-system-bar>

            <v-row no-gutters align="center" class="flex-nowrap">
              <v-col cols="6">
                <v-list-item class="font-weight-medium">
                  <v-list-item-icon>
                    <v-badge :content="item.details.length">
                      <v-icon>mdi-account-multiple</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                  {{ item.details.length }} Personen
                </v-list-item>
              </v-col>
              <v-col> Spesen </v-col>
              <v-col cols="3">
                <CurrencyInput v-model="amountToSet" />
              </v-col>

              <v-col cols="1">
                <v-btn
                  class="mx-2"
                  icon
                  color="success"
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.lgAndUp"
                  @click="setAmount"
                  ><v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-divider />

            <v-toolbar flat>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-sort</v-icon>
                </template>
                <span
                  >Sortieroptionen: gruppiert pro Klasse, nach Name oder nach
                  Betrag</span
                >
              </v-tooltip>

              <v-btn-toggle dense v-model="sortOption" mandatory>
                <v-btn text value="schoolClass">Klasse</v-btn>
                <v-btn text value="name">Name</v-btn>
                <v-btn text value="amount" @click="sortDetailsAmount"
                  >Betrag</v-btn
                > </v-btn-toggle
              ><v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                </template>
                <span
                  >Suche nach Klasse, z.B. "G", oder "27", oder "G27b" und Namen
                  der Person</span
                >
              </v-tooltip>

              <v-text-field
                v-model="searchSchoolClass"
                label="Klasse"
                hideDetails
                class="mr-2"
              ></v-text-field>
              <v-text-field
                v-model="searchName"
                label="Name"
                hideDetails
                class="ml-2"
              ></v-text-field>
            </v-toolbar>
            <v-divider />
            <v-row
              no-gutters
              v-for="(detail, index) in detailsFiltered"
              :key="index"
              align="center"
              class="flex-nowrap"
            >
              <v-col cols="6">
                <PersonItemBasic
                  :dense="$vuetify.breakpoint.xs"
                  :value="detail.person"
                ></PersonItemBasic>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-if="detail.person.type.code === 'teacher'"
                  v-model="detail.expenses"
                  inset
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <CurrencyInput v-model="detail.amount" @input="saveStorage" />
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-2"
                  icon
                  color="error"
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.lgAndUp"
                  @click="removeDetail(detail)"
                  ><v-icon>mdi-trash-can</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-divider />
            <v-card-actions>
              <v-btn text disabled> Personen hinzufügen:</v-btn>
              <v-spacer />
              <PersonCoursePicker color="success" @input="addCourse" />
              <PersonEventPicker color="success" @input="addEvent" />
              <PersonPicker color="success" @input="addPersons" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CurrencyValue from "common/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";
import GradesInput from "./Components/GradesInput.vue";
import InvoiceAmount from "./Components/InvoiceAmount.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonCoursePicker from "./Components/PersonCoursePicker.vue";
import PersonEventPicker from "./Components/PersonEventPicker.vue";
import PersonPicker from "./Components/PersonPicker.vue";
import SchoolYearInput from "common/components/SchoolYearInput.vue";

import { today } from "common/utils/date.js";
import {
  compareStudentsBySchoolClass,
  comparePeople,
  searchPerson,
} from "common/utils/people.js";
import { isValidIBANNumber } from "common/utils/helper.js";
export default defineComponent({
  components: {
    CurrencyInput,
    CurrencyValue,
    DateValue,
    GradesInput,
    InvoiceAmount,
    LookupValueInput,
    PersonCoursePicker,
    PersonEventPicker,
    PersonItemBasic,
    PersonPicker,
    SchoolYearInput,
  },
  props: { id: {}, page: { type: String, default: "InvoicePending" } },
  data() {
    return {
      accounts: [],
      amountToSet: 0.0,
      copiesColor: 0,
      copiesGrayscale: 0,
      events: [],
      loading: false,
      saving: false,
      item: {
        amount: 0,
        details: [],
        employee: 0,
        grades: { 1: false, 2: false, 3: false, 4: false },
      },
      priceGrayscale: 10,
      priceColor: 35,
      payees: [],
      rules: {
        required: (value) => !!value || "wird benötigt.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        iban: (value) => {
          return isValidIBANNumber(value) || "Ungültige IBAN.";
        },
      },
      saveAllowed: false,
      searchSchoolClass: "",
      searchName: "",
      sortOption: "schoolClass",
      tolerance: true,
    };
  },
  computed: {
    abortTarget() {
      return this.isNew
        ? { name: "InvoicePending" }
        : { name: "InvoiceDetails", params: { id: this.item.id } };
    },
    detailsFiltered() {
      if (!this.item) {
        return [];
      }

      if (!this.searchSchoolClass && !this.searchName) {
        return this.item.details;
      }

      return this.item.details.filter(
        (el) =>
          (!this.searchSchoolClass ||
            (el.person.schoolClass &&
              el.person.schoolClass.code.includes(this.searchSchoolClass))) &&
          (!this.searchName || searchPerson(el.person, this.searchName))
      );
    },
    gym1OK() {
      if (this.item.details.length < 1) return true;
      if (!this.item.grades[4]) return true;
      const hasOther = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code != "GYM1"
      );
      return !hasOther;
    },
    mixedGradesOK() {
      if (!this.item.account || this.item.account.code === "fieldTrip")
        return true;
      if (this.item.details.length < 1) return true;
      const hasGym1 = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code == "GYM1"
      );
      const hasOther = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code != "GYM1"
      );
      return hasGym1 != hasOther;
    },
    copies() {
      return this.item.payee && this.item.payee.code == "schoolFund";
    },
    copiesAmount() {
      return (
        (this.priceGrayscale * this.copiesGrayscale +
          this.priceColor * this.copiesColor) /
        100
      );
    },
    isNew() {
      return !this.id;
    },
    savable() {
      return (
        this.item.amount > 0 &&
        this.item.description &&
        this.item.details.length > 0 &&
        this.item.account &&
        this.item.payee &&
        this.tolerance &&
        this.mixedGradesOK &&
        this.gym1OK
      );
    },
  },
  watch: {
    sortOption() {
      this.sortDetails();
      localStorage.setItem("FinanceInvoiceSortOption", this.sortOption);
    },
  },
  methods: {
    async addCourse(course) {
      const students = await this.apiList({
        resource: "person/person",
        query: `course=${course.id}`,
      });
      if (!this.item.event) this.item.event = course.title;
      students.forEach((person) => {
        if (!this.hasDetail(person))
          this.item.details.push({ person: person, amount: 0 });
      });
      this.sortDetails();
    },
    async addEvent(event) {
      const students = event.participants;
      if (!this.item.event) this.item.event = event.description;
      students.forEach((person) => {
        if (!this.hasDetail(person))
          this.item.details.push({ person: person, amount: 0 });
      });
      this.sortDetails();
    },
    async addPersons(persons) {
      for (const person of persons) {
        if (!this.hasDetail(person)) {
          // Bei Mitarbeitern und Konto Exkursionen werden per Default Spesen erfasst
          const expenses =
            person.type.code === "teacher" &&
            this.item.account &&
            this.item.account.code === "fieldTrip";
          this.item.details.push({
            person: person,
            amount: 0,
            expenses: expenses,
          });
        }
      }
      this.sortDetails();
    },
    assign() {
      const details = this.item.details;
      details.forEach((detail) => (detail.amount = this.copiesAmount));
      this.item.amount = this.copiesAmount * details.length;
    },
    distribute() {
      const details = this.item.details;
      const amount =
        Math.floor((100 * this.item.amount) / details.length) / 100;
      details.forEach((detail) => (detail.amount = amount));
    },
    hasDetail(person) {
      const details = this.item.details;
      for (let i = 0; i < details.length; ++i) {
        if (details[i].person.id === person.id) return true;
      }
      return false;
    },
    removeStorage() {
      localStorage.removeItem("teacherInvoiceForm");
    },
    checkStorage() {
      return localStorage.getItem("teacherInvoiceForm") != null;
    },
    loadStorage() {
      this.item = JSON.parse(localStorage.getItem("teacherInvoiceForm"));
    },
    saveStorage() {
      if (this.isNew && !this.loading) {
        localStorage.setItem("teacherInvoiceForm", JSON.stringify(this.item));
      }
    },
    updateTolerance(tolerance) {
      this.tolerance = tolerance;
    },
    removeDetail(detail) {
      const index = this.item.details.indexOf(detail);
      this.item.details.splice(index, 1);
    },
    async save() {
      this.saving = true;
      if (this.item.payee.code == "employee") {
        localStorage.setItem("iban", this.item.iban);
      }
      // deep copy
      const data = JSON.parse(JSON.stringify(this.item));
      // replace person-json with person-id
      data.details.forEach((detail) => (detail.person = detail.person.id));
      if (this.isNew) {
        const result = await this.apiPost({
          resource: "finance/invoice",
          data: data,
        });
        this.id = result.id;
      } else {
        await this.apiPut({
          resource: "finance/invoice",
          id: this.id,
          data: data,
        });
      }
      this.saving = false;
      this.removeStorage();
      this.$router.push({
        name: "InvoiceDetails",
        params: { id: this.id },
      });
    },
    setAmount() {
      this.item.details.forEach((detail) => (detail.amount = this.amountToSet));
      this.item.amount = this.amountToSet * this.item.details.length;
    },

    sortDetails() {
      if (this.sortOption == "schoolClass") {
        this.sortDetailsSchoolclass();
      } else if (this.sortOption == "amount") {
        this.sortDetailsAmount();
      } else {
        this.sortDetailsName();
      }
    },
    sortDetailsAmount() {
      this.item.details.sort((a, b) =>
        a.amount > b.amount
          ? 1
          : a.amount < b.amount
          ? -1
          : comparePeople(a.person, b.person)
      );
    },
    sortDetailsSchoolclass() {
      this.item.details.sort((a, b) =>
        compareStudentsBySchoolClass(a.person, b.person)
      );
    },
    sortDetailsName() {
      this.item.details.sort((a, b) => comparePeople(a.person, b.person));
    },
  },
  async created() {
    this.loading = true;
    this.item.date = today();

    this.accounts = await this.apiList({
      resource: "finance/invoiceaccount?course",
    });

    if (this.item.employee.id || this.$_profilePerson) {
      const employeeId = this.item.employee.id
        ? this.item.employee.id
        : this.$_profilePerson.id;
      const courses = (
        await this.apiList({
          resource: "course/course",
          query: `person=${employeeId}&startDate=${this.item.date}&endDate=${this.item.date}`,
        })
      ).map((el) => el.title);
      const history = (
        await this.apiList({
          resource: "finance/eventoverview",
        })
      ).map((el) => el.description);

      this.events = [...new Set([...courses, ...history])].sort((a, b) =>
        a.localeCompare(b)
      );
    }

    this.payees = await this.apiList({ resource: "finance/payee" });
    if (this.isNew) {
      // check for local storage and ask
      if (this.checkStorage()) {
        if (
          await this.$root.confirm({
            message:
              "Es ist eine ungespeicherte Rechnung verfügbar. Soll diese geladen werden?",
            color: "info",
            icon: "mdi-floppy",
          })
        ) {
          this.loadStorage();
        } else {
          this.item.schoolYear = (
            await this.apiList({
              resource: "common/schoolyear",
              query: `offsetpast=0&offsetfuture=0`,
            })
          )[0];
          this.item.iban =
            this.$_profilePerson.iban || localStorage.getItem("iban");
        }
      } else {
        this.item.schoolYear = (
          await this.apiList({
            resource: "common/schoolyear",
            query: `offsetpast=0&offsetfuture=0`,
          })
        )[0];
        this.item.iban =
          this.$_profilePerson.iban || localStorage.getItem("iban");
      }
    } else {
      this.item = await this.apiGet({
        resource: "finance/invoice",
        id: this.id,
      });
      this.sortDetails();
    }
    this.loading = false;
  },
});
</script>
